
import { defineComponent, onMounted, ref } from "vue";
import {process_gcredeem, process_sodexoredeem, getGCModelData}  from "@/api/productgc";
import Swal from "sweetalert2/dist/sweetalert2.min.js";


export default defineComponent({
  name: "productgcprocess", 

  methods: {
    async getGCModelData(){ 


     
      this.loading = true;
      /*
      const response = await getGCModelData();
      this.gcModelData = response.data;
      this.isEnabledCountryGCRedeemButton = response.data.isEnabledCountryGCRedeemButton;
      this.ccode = this.gcModelData.countryCode;
      this.branches = this.gcModelData.branches;
      this.usid = this.gcModelData.usid;
      this.availableSodexo = this.gcModelData.availableSodexo;
      this.isEnabledCountryGCRedeemButton = this.gcModelData.isEnabledCountryGCRedeemButton;
      console.log(this.gcModelData);
      */



      this.isallowedTravelGCRedeem = this.gcModelData.isallowedTravelGCRedeem;
      this.isEnabledCountryGCRedeemButton = this.gcModelData.isEnabledCountryGCRedeemButton;
      this.ccode = this.gcModelData.countryCode;
      this.branches = this.gcModelData.branches;
      this.usid = this.gcModelData.usid;
      this.availableSodexo = this.gcModelData.availableSodexo;
      this.isEnabledCountryGCRedeemButton = this.gcModelData.isEnabledCountryGCRedeemButton;

      this.loading = false;
    },
    async process_sodexoredeem(){
      if(isNaN(this.sodexo_quantity) || this.sodexo_quantity == 0){
        this.loading = true;
        Swal.fire({
            title: "",
            text: "Total Sodexo must be greater than 0.",
            icon: "error",
            confirmButtonClass: "btn btn-secondary"
          }); 
          this.loading = false;
          return;
      } else {
        this.loading = true;
        var params = {"gc_redeem" : this.sodexo_quantity, "gc_position" : "L", "sodexbranch" : this.branchcode_selected};
        const response = await process_sodexoredeem(params);
        if(response != null){
          this.loading = false;
          if (response.data.result == "SUCCESS") {
            Swal.fire({
              title: "",
              text: response.data.message,
              icon: "success",
              confirmButtonClass: "btn btn-secondary"
            }); 
            window.location.reload();
            return; 
          } else if(response.data.result == "FAILED"){
            Swal.fire({
              title: "",
              text: response.data.message,
              icon: "error",
              confirmButtonClass: "btn btn-secondary"
            }); 
            return; 
          }
        }
      }
      this.loading = false;
    },
    async processGCRedeem(){ 
    if(isNaN(this.gc_redeem) || this.gc_redeem == 0){
        this.loading = true;
        Swal.fire({
            title: "",
            text: "Total must be greater than 0.",
            icon: "error",
            confirmButtonClass: "btn btn-secondary"
          }); 
          this.loading = false;
          return;
      } else {
        this.loading = true;
        var params = {"gc_redeem" : this.gc_redeem, "gc_position" : this.gc_position, "gc_type" : this.gc_type}
        const response = await process_gcredeem(params);

        if(response != null){
          this.loading = false;
          if (response.data.result == "SUCCESS") {
            Swal.fire({
              title: "",
              text: response.data.message,
              icon: "success",
              confirmButtonClass: "btn btn-secondary"
            }); 
            window.location.reload();
            return; 
          } else if(response.data.result == "FAILED"){
            Swal.fire({
              title: "",
              text: response.data.message,
              icon: "error",
              confirmButtonClass: "btn btn-secondary"
            }); 
            return; 
          }
        }
      }
      this.loading = false;
    },
  },
  mounted(){
    this.getGCModelData();
  },
  data(){
      return { 
        loading: false,
        gc_redeem: 0,
        gc_position: "L",
        gcModelData : this.gcmodeldata as any,
        ccode: -1,
        sodexo_quantity : 0,
        branches : [] as any,
        branchcode_selected : "",
        usid : "",
        availableSodexo : 0,
        isEnabledCountryGCRedeemButton : false,
        gc_type : "PR",
        isallowedTravelGCRedeem : false
      }
  },
  components: {
    
  },
  props: {
    widgetClasses: String,
    gcmodeldata : Object,
  }
});

