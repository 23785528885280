import request from '@/utils/request'

//Load Product GC Model Data
export function getGCModelData() {
  return request({ url: 'vgetgcmodeldata', method: 'get'})
} 

export function loadPaginatedGc(param) {
  return request({ url: 'vgiftcheque', method: 'post', data: param})
} 

export function getRedeemOptions(param) {
  return request({ url: 'vgetredeemoptions', method: 'post', data: param})
} 

export function submitGCOptions(param) {
  return request({ url: 'vsubmitgcoption', method: 'post', data: param})
}


export function process_gcredeem(params) {
  return request({ url: 'vgcredeem', method: 'post', data: params})
} 


export function process_sodexoredeem(params) {
  return request({ url: 'vsodexoredeem', method: 'post', data: params})
}



