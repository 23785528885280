
import { defineComponent } from "vue";
import { loadPaginatedGc, getRedeemOptions, submitGCOptions}  from "@/api/productgc";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

export default defineComponent({
  name: "productgchistory", 
  methods: {
    
    async loadPaginatedGc(){
      let param = {page: 1};
      this.loading = true;
      const response = await loadPaginatedGc(param);
      //console.log(response.data.pageList);
      this.list = response.data.pageList;
      
      this.loading = false;
    },
    async loadRedeemOptions(pcod){
      let param = {pcod: pcod};
      const response = await getRedeemOptions(param);
      this.redeemOptions = response.data.redeemOptions;
      //console.log(this.gc.options.length);
    },
    async submitGCOption(pcode, recid, index, pin1){

      let param = {
        pcod: pcode,
        recid : recid,
        option : this.gc.options[index],
        pin1 : pin1
      };

      const response = await submitGCOptions(param);
      if(response != null){
          this.loading = false;
          if (response.data.result == "SUCCESS") {
            Swal.fire({
              title: "",
              text: response.data.message,
              icon: "success",
              confirmButtonClass: "btn btn-secondary"
            }); 
            window.location.reload();
            return; 
          } else if(response.data.result == "FAILED"){
            Swal.fire({
              title: "",
              text: response.data.message,
              icon: "error",
              confirmButtonClass: "btn btn-secondary"
            }); 
            return; 
          }
        }


    }
  },
  mounted(){
      //this.getgcmodelData();
      this.loadPaginatedGc();
      
  },
  data(){
      return { 
        list : [],
        redeemOptions : [],
        loading: true,
        pcode : "",
        modal_desc : "",
        modal_ticket : "",
        gc: {
        options: []
        },
        showOptions : true,
      }
  },
  components: {
  },
  props: {
    widgetClasses: String
  }
});

