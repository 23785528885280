
import { defineComponent, computed } from "vue";
import {getBalanceDetails} from "@/api/dashboard"
import { getCurrentUser } from "@/utils/index"
import {getIGlowVotingPower}  from "@/api/estore";

export default defineComponent({
  name: "ecpedpdashboardnavigator",
  components: {},
  props: {
  },
  data(){
    return{
      balancedetails:[],
      user:"",
      loadingBalance:true,
      votingPower : 0,
      pt : ""
    }
  },
  methods:{
    async getBalanceDetails(){
      this.loadingBalance = true;
      const response = await getBalanceDetails();
      this.balancedetails = response.data;
      this.loadingBalance = false;
    },
    async getiglowvotingpower(){
      const response = await getIGlowVotingPower();
      this.votingPower = response.data.votingPower;
      console.log(this.votingPower);
    },
  },
  mounted(){
    this.user = getCurrentUser();
    this.getBalanceDetails();
    //this.getiglowvotingpower();
    this.pt = String(localStorage.getItem("pt"));
  }
});
