
import { defineComponent, onMounted, ref } from "vue";
import { config } from "@/core/helpers/config";
import { themeName } from "@/core/helpers/documentation";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import ProductGCHistory from "@/components/productgc/ProductGCHistory.vue";
import ProductGCProcess from "@/components/productgc/ProductGCProcess.vue";
import BalanceDetails from "@/components/cards/BalanceDetails.vue";
import {getGCModelData}  from "@/api/productgc";
// import CompanyPolicy from "@/components/companypolicy/CompanyPolicy.vue";
// import ECShopAdvisory from  "@/views/ECShopAdvisory.vue";

export default defineComponent({
  name: "productgc",
  components: {
    ProductGCHistory,
    ProductGCProcess,
    BalanceDetails,
    // CompanyPolicy,
    // ECShopAdvisory
  },
  data(){
    return{
      loading:true,
      gcmodeldata:[],
      isEnabledCountryGCRedeemButton: true,
      isOverriden : false,
    }
  },
  methods:{
    async getgcmodelData(){ 
      this.loading = true;
      const response = await getGCModelData();
      this.gcmodeldata = response.data;
      this.isEnabledCountryGCRedeemButton = response.data.isEnabledCountryGCRedeemButton;
      console.log(this.gcmodeldata);
      this.loading = false;
    },
  },
  mounted(){
    this.getgcmodelData();
    if(localStorage.getItem("override_cifid") != null){
        this.isOverriden = true;
      }
  },
  setup() {
    const tabIndex = ref(0);

    onMounted(() => {
      // set the tab from previous
      tabIndex.value = parseInt(localStorage.getItem("productGCTab") || "0");

      setCurrentPageTitle("Product GC");
      
    });

    
  }
});
